import qs from 'qs';

if (!process.env.NEXT_PUBLIC_DW_WP_API_HOST) {
  throw new Error(`Enviorment variable NEXT_PUBLIC_DW_WP_API_HOST is missing`);
}

const scheme = process.env.NEXT_PUBLIC_DW_WP_API_SCHEME || 'https';
export const endpoint = `${scheme}://${process.env.NEXT_PUBLIC_DW_WP_API_HOST}/wp-json/wp/v2`;

const decodeHtmlEntities = (str) =>
  str
    .replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec);
    })
    .replace(/&amp;/g, '&');

const formatInternalBodyLinks = (html) => {
  const regex = /(http|https):\/\/(news|editorial)\.dailywire\.com\//gm;
  const subst = `https://www.dailywire.com/`;
  // The substituted value will be contained in the result variable
  const result = html.replace(regex, subst);
  return result;
};

const formatEmbeddedFeaturedMedia = (post) => {
  const hasImage =
    post._embedded['wp:featuredmedia'] &&
    post._embedded['wp:featuredmedia'][0] &&
    post._embedded['wp:featuredmedia'][0].source_url;

  return hasImage
    ? {
        id: post._embedded['wp:featuredmedia'][0].id,
        title: decodeHtmlEntities(post._embedded['wp:featuredmedia'][0].title.rendered),
        alt: post._embedded['wp:featuredmedia'][0].alt_text ? post._embedded['wp:featuredmedia'][0].alt_text : null,
        caption: post._embedded['wp:featuredmedia'][0].caption.rendered
          ? post._embedded['wp:featuredmedia'][0].caption.rendered
          : null,
        url: post._embedded['wp:featuredmedia'][0].source_url,
      }
    : null;
};

const formatEmbeddedAuthor = (post) => ({
  id: post._embedded.author[0].id,
  name: post._embedded.author[0].name,
  slug: post._embedded.author[0].slug,
});

const formatEmbeddedCategory = (post) => {
  if (!post._embedded['wp:term']) return null;
  // Filtering out "Uncategorized" since Wordpress auto checks this if no Category is specified
  const categories = post._embedded['wp:term'][0]
    .filter((category) => category.name !== 'Uncategorized')
    .map((category) => ({
      id: category.id,
      name: decodeHtmlEntities(category.name),
    }));

  // Return null if the only category is "Uncategorized"

  if (categories.length === 0) return null;

  return categories;
};

const formatWpTagAsTopic = (tag) => {
  const { acf } = tag;
  return {
    id: tag.id,
    name: tag.name,
    slug: tag.slug,
    description: tag.description,
    count: tag.count,
    image: acf.featured_image
      ? {
          id: acf.featured_image.id,
          title: acf.featured_image.title,
          alt: acf.featured_image.alt,
          url: acf.featured_image.url,
        }
      : null,
    body: acf.body ? acf.body : '',
    trending: acf.trending ? acf.trending : false,
  };
};

const formatWpUserAsAuthor = (user) => {
  const { acf } = user;
  return {
    id: user.id,
    name: user.name,
    slug: user.slug,
    twitter: acf.twitter_handle ? acf.twitter_handle : '',
    instagram: acf.instagram_handle ? acf.instagram_handle : '',
    biography: acf.biography ? acf.biography : '',
    headshot: acf.headshot ? acf.headshot : '',
  };
};

export const formatWpPost = (post) => {
  const { acf } = post;

  return {
    id: post.id,
    date: post.date,
    modified: post.modified,
    slug: post.slug,
    title: decodeHtmlEntities(post.title.rendered),
    title_alt: acf?.title_alt ? decodeHtmlEntities(acf.title_alt) : null,
    subhead: acf?.subhead ?? null,
    content: formatInternalBodyLinks(post.content.rendered),
    excerpt: post.excerpt.rendered,
    evergreen: acf?.evergreen === 'true' ? true : false,
    account_required: acf?.account_required === 'true' ? true : false,
    members_only: acf?.members_only === 'true' ? true : false,
    preview_length: acf?.preview_length ?? null,
    collection: acf?.collection ?? null,
    collection_order: Number(acf?.collection_order) ?? null,
    layout: acf?.layout ?? null,
    image: formatEmbeddedFeaturedMedia(post),
    author: formatEmbeddedAuthor(post),
    categories: formatEmbeddedCategory(post),
    topics: post._embedded['wp:term']
      ? post._embedded['wp:term'][1].map((topic) => ({
          id: topic.id,
          name: topic.name,
          slug: topic.slug,
        }))
      : null,
    // yoast_meta: post.yoast_meta,
  };
};

const formatWpPage = (page) => {
  return {
    id: page.id,
    date: page.date,
    modified: page.modified,
    slug: page.slug,
    title: decodeHtmlEntities(page.title.rendered),
    content: formatInternalBodyLinks(page.content.rendered),
    excerpt: page.excerpt.rendered,
    // yoast_meta: page.yoast_meta,
  };
};

const formatWpCollection = (post) => {
  return {
    id: post.id,
    slug: post.slug,
    title: decodeHtmlEntities(post.title.rendered),
    date: post.date,
    modified: post.modified,
    content: post.content.rendered,
    excerpt: post?.excerpt?.rendered ?? '',
    author: formatEmbeddedAuthor(post),
    topics: post._embedded['wp:term']
      ? post._embedded['wp:term'][0].map((topic) =>
          Object.assign({}, { id: topic.id, name: topic.name, slug: topic.slug }),
        )
      : null,
    image: formatEmbeddedFeaturedMedia(post),
  };
};

export const getLeadPosts = async (limit) => {
  const res = await fetch(
    `${endpoint}/posts?${qs.stringify({
      prominence: 'lead',
      per_page: limit,
      _embed: 1,
    })}`,
  );
  const json = await res.json();
  return json.map((post) => formatWpPost(post));
};

export const getCoverPosts = async (limit) => {
  const res = await fetch(
    `${endpoint}/posts?${qs.stringify({
      prominence: 'cover',
      per_page: limit,
      'filter[orderby]': 'date',
      order: 'desc',
      _embed: 1,
    })}`,
  );
  const json = await res.json();
  return json.map((post) => formatWpPost(post));
};

export const getFeaturedPosts = async (limit) => {
  const res = await fetch(
    `${endpoint}/posts?${qs.stringify({
      prominence: 'featured',
      per_page: limit,
      'filter[orderby]': 'date',
      order: 'desc',
      _embed: 1,
    })}`,
  );
  const json = await res.json();
  return json.map((post) => formatWpPost(post));
};

export const getRecentPosts = async (limit, offset = 0, exclude = [], additionalParams = {}) => {
  const params = qs.stringify({
    per_page: limit,
    page: offset / limit + 1,
    'filter[orderby]': 'date',
    order: 'desc',
    exclude: exclude.join(','),
    _embed: 1,
    ...additionalParams,
  });
  const res = await fetch(`${endpoint}/posts?${params}`);
  const json = await res.json();
  return json.map((post) => formatWpPost(post));
};

export const getFullPost = async (slug) => {
  const res = await fetch(
    `${endpoint}/posts?${qs.stringify({
      slug: slug,
      _embed: 1,
    })}`,
  );
  const posts = await res.json();

  if (!(posts && posts.length !== 0)) {
    return null;
  }

  let post = formatWpPost(posts[0]);

  if (post.collection) {
    post.collection = await getCollectionWithPosts(post.collection);
  }

  return post;
};

export const getPage = async (slug) => {
  const res = await fetch(
    `${endpoint}/pages?${qs.stringify({
      slug: slug,
      _embed: 1,
    })}`,
  );
  const pages = await res.json();
  if (!(pages && pages.length !== 0)) {
    return null;
  }
  return formatWpPage(pages[0]);
};

export const getPages = async (limit) => {
  const res = await fetch(
    `${endpoint}/pages?${qs.stringify({
      'filter[orderby]': 'date',
      order: 'desc',
      per_page: limit,
      _embed: 1,
    })}`,
  );
  const pages = await res.json();
  return pages.map((page) => formatWpPost(page));
};

export const getTopicWithPosts = async (slug, postLimit = 10) => {
  const tagsResponse = await fetch(`${endpoint}/tags?${qs.stringify({ slug })}`);
  const tags = await tagsResponse.json();

  if (!(tags && tags.length !== 0)) {
    return {
      topic: null,
      posts: null,
    };
  }

  const topic = formatWpTagAsTopic(tags[0]);

  const postsResponse = await fetch(
    `${endpoint}/posts?${qs.stringify({
      tags: topic.id,
      per_page: postLimit,
      'filter[orderby]': 'date',
      order: 'desc',
      _embed: 1,
    })}`,
  );
  const posts = await postsResponse.json();

  return {
    topic: topic,
    posts: posts.map((post) => formatWpPost(post)),
  };
};

export const getTrendingTopics = async (limit = 8) => {
  const trendingTagsResponse = await fetch(
    `${endpoint}/tags?${qs.stringify({
      per_page: limit,
      trending: 1,
    })}`,
  );
  const trendingTags = await trendingTagsResponse.json();

  if (!(trendingTags && trendingTags.length !== 0)) {
    return {
      trendingTags: null,
    };
  }

  return trendingTags.map((trendingTag) => formatWpTagAsTopic(trendingTag));
};

export const getAuthorWithPosts = async (slug, postLimit = 10) => {
  const authorsResponse = await fetch(`${endpoint}/users?${qs.stringify({ slug: slug })}`);
  const authors = await authorsResponse.json();

  if (!(authors && authors.length !== 0)) {
    return {
      author: null,
      posts: null,
    };
  }

  const author = formatWpUserAsAuthor(authors[0]);

  const postsResponse = await fetch(
    `${endpoint}/posts?${qs.stringify({
      author: author.id,
      per_page: postLimit,
      'filter[orderby]': 'date',
      order: 'desc',
      _embed: 1,
    })}`,
  );
  const posts = await postsResponse.json();

  return {
    author: author,
    posts: posts.map((post) => formatWpPost(post)),
  };
};

export const getRecentCollections = async (limit, offset = 0) => {
  const params = qs.stringify({
    per_page: limit,
    page: offset / limit + 1,
    'filter[orderby]': 'date',
    order: 'desc',
    _embed: 1,
  });

  const res = await fetch(`${endpoint}/post_collections?${params}`);
  const data = await res.json();

  return data.map((collection) => formatWpCollection(collection));
};

export const getCollectionBySlugWithPosts = async (slug) => {
  const collectionRes = await fetch(
    `${endpoint}/post_collections?${qs.stringify({
      slug: slug,
      _embed: 1,
    })}`,
  );
  const collectionData = await collectionRes.json();

  if (!collectionData) {
    return null;
  }

  const collection = formatWpCollection(collectionData[0]);

  const postsRes = await fetch(
    `${endpoint}/posts?${qs.stringify({
      collection: collection.id,
      // per_page corrisponds to the ACF field limit in wordpress
      per_page: 16,
      _embed: 1,
    })}`,
  );

  const postsData = await postsRes.json();
  const posts = postsData.map((post) => formatWpPost(post));

  return {
    ...collection,
    posts,
  };
};

export const getCollectionWithPosts = async (id) => {
  try {
    const collectionRes = await fetch(`${endpoint}/post_collections/${id}?${qs.stringify({ _embed: 1 })}`);
    const collectionData = await collectionRes.json();

    if (collectionData.code && collectionData.code === 'rest_forbidden') {
      return null;
    }

    if (!collectionData) {
      return null;
    }

    const collection = formatWpCollection(collectionData);

    const postsRes = await fetch(
      `${endpoint}/posts?${qs.stringify({
        collection: collection.id,
        // per_page corrisponds to the ACF field limit in wordpress
        per_page: 16,
        _embed: 1,
      })}`,
    );

    const postsData = await postsRes.json();
    const posts = postsData.map((post) => formatWpPost(post));

    return {
      ...collection,
      posts,
    };
  } catch (err) {
    console.log(err, '***');
  }
};
